<!--
 * @Description:
 * @Autor: Zhongyu
 * @Date: 2021-09-05 20:39:21
 * @LastEditors: Zhongyu
 * @LastEditTime: 2021-10-19 14:51:14
-->
<template>
  <div>
    <modal :visible="visible" :title="formTitle.title" @close="handleClose">
      <div class="modal-body" style="position: relative;">
        <div style="position: absolute;top: -30px;color: red;">
          {{ warnList.refundRemind }}
        </div>
        <el-form class="modal-form" ref="ruleForm" :model="form" :rules="rules">
          <el-form-item
            label="申请类型"
            prop="status"
            v-if="form.status != '1'"
          >
            <el-select
              :popper-append-to-body="false"
              class="form-select"
              v-model="form.status"
              placeholder="请选择申请类型"
            >
              <el-option label="退货" value="2"></el-option>
              <el-option label="换货" value="3"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="formTitle.lable" prop="type">
            <el-select
              :popper-append-to-body="false"
              class="form-select"
              v-model="form.type"
              :placeholder="`请选择${formTitle.lable}`"
            >
              <el-option
                v-for="item in types"
                :key="item"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注" prop="desc">
            <el-input
              class="form-textarea"
              type="textarea"
              :rows="5"
              v-model="form.desc"
              placeholder="请填写备注"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="onSubmit">
          确 定
        </el-button>
      </span>
    </modal>
  </div>
</template>

<script>
import Modal from "@/components/Modal";
export default {
  components: {
    Modal
  },
  data() {
    return {
      visible: false,
      formTitle: {
        title: "申请退货",
        lable: "退货原因"
      },
      types: [
        "不想要了",
        "地址信息填写错误",
        "配送时间问题",
        "商品选错/多选",
        "物流无跟踪记录",
        "商品无货",
        "货物破损已拒签",
        "未收到货",
        "其他"
      ],

      form: {
        status: 1,
        type: "",
        desc: ""
      },
      orderId: null,
      rules: {
        status: [
          {
            required: true,
            message: "请选择申请类型",
            trigger: "change"
          }
        ],
        type: [
          {
            required: true,
            message: "请选择原因",
            trigger: "change"
          }
        ]
      },

      //  退换货提醒
      warnList: {
        type: 8,
        refundRemind: ""
      }
    };
  },
  mounted() {},
  created() {
    this.getUserGuide();
  },
  methods: {
    init(id, status) {
      this.orderId = id;
      this.form.status = status.toString();
      if (status == 1) {
        this.formTitle = {
          title: "申请退款",
          lable: "退款原因"
        };
        this.types = [
          "不想要了",
          "地址信息填写错误",
          "配送时间问题",
          "商品选错/多选",
          "物流无跟踪记录",
          "商品无货",
          "货物破损已拒签",
          "未收到货",
          "其他"
        ];
      } else if (status == 2) {
        this.formTitle = {
          title: "申请退货/换货",
          lable: "退货/换货原因"
        };
        this.types = [
          "不想要了",
          "地址信息填写错误",
          "配送时间问题",
          "商品选错/多选",
          "物流无跟踪记录",
          "商品无货",
          "货物破损已拒签",
          "未收到货",
          "商品质量问题",
          "型号和描述不符",
          "其他"
        ];
      }
      this.title = this.visible = true;
      if (this.$refs.ruleForm) {
        this.$refs.ruleForm.resetFields();
      }
    },
    handleClose() {},
    onSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.refund();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    /**
     * 申请退款/退货/换货
     */
    refund() {
      let params = {
        id: this.orderId,
        refundStatus: this.form.status,
        refundWhy: this.form.type,
        refundNote: this.form.desc
      };
      const loading = this.$loading({
        lock: true,
        text: "请求中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      this.$api
        .refund(params)
        .then(() => {
          if (this.form.status == "1") {
            this.$message({
              message: "恭喜您，申请退款成功！",
              type: "success"
            });
          } else if (this.form.status != "1") {
            this.$message({
              message: "恭喜您，申请退货/换货成功！",
              type: "success"
            });
          }
          this.orderId = null;
          this.visible = false;
          this.$parent.getOrderDetail();
        })
        .catch(err => {
          this.$message.error(err?.msg);
        })
        .finally(() => {
          loading.close();
        });
    },
    getUserGuide() {
      this.$api.getUserGuide({ type: 8 }).then(res => {
        let { code, data } = res;
        if (code == 200) {
          this.warnList = data;
          console.log(this.warnList.refundRemind);
        }
      });
    }
  }
};
</script>

<style lang="less" scoped></style>
