<!--
 * @Description:
 * @Autor: Zhongyu
 * @Date: 2021-09-05 21:08:06
 * @LastEditors: Lizy
 * @LastEditTime: 2021-12-04 19:15:49
-->
<template>
  <div class="purchase-details">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: path }">采购单</el-breadcrumb-item>
      <el-breadcrumb-item>订单详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="top">
      <div class="top-left">
        <div class="top-left-no">订单号：{{ data.orderNum }}</div>
        <div class="top-left-type">
          {{ statusName(data.status) }}
        </div>
        <div class="top-left-time">
          {{ data.createDate }}
        </div>
        <div class="top-left-operation-column" v-show="data.status == 1">
          <el-button type="danger" @click="handleApplyRefund(1)">
            申请退款
          </el-button>
        </div>
        <div class="top-left-operation-column" v-show="data.status == 2">
          <el-button type="warning" @click="handleConfirmGoods">
            确认收货
          </el-button>
          <el-button type="danger" @click="handleApplyRefund(2)">
            退货/换货
          </el-button>
        </div>
      </div>
      <div class="top-right">
        <div class="right-item">
          <div class="item">
            <span
              class="iconfont icon-dingdan1"
              :class="{ 'iconfont-sel': data.status < 4 }"
            ></span>
            <div class="type" :class="{ arrive: data.status < 4 }">
              已下单
            </div>
            <div class="time" v-show="data.status < 4">
              {{ data.createDate }}
            </div>
          </div>
        </div>
        <img :src="data.status < 4 && data.status > 1 ? arrows_sel : arrows" />
        <div class="right-item">
          <div class="item">
            <!-- <img :src="data.status < 4 && data.status > 1 ? src_sel : src" /> -->
            <span
              class="iconfont icon-yifahuo"
              style="font-size: 38px;margin-bottom:-2px"
              :class="{ 'iconfont-sel': data.status < 4 && data.status > 1 }"
            ></span>
            <div
              class="type"
              :class="{ arrive: data.status < 4 && data.status > 1 }"
            >
              已发货
            </div>
            <div class="time" v-show="data.status < 4 && data.status > 1">
              {{ data.deliverTime }}
            </div>
          </div>
        </div>
        <img :src="data.status < 4 && data.status > 2 ? arrows_sel : arrows" />
        <div class="right-item">
          <div class="item">
            <span
              class="iconfont icon-dingdan1"
              :class="{ 'iconfont-sel': data.status < 4 && data.status > 2 }"
            ></span>
            <div
              class="type"
              :class="{ arrive: data.status < 4 && data.status > 2 }"
            >
              已完成
            </div>
            <div class="time" v-show="data.status < 4 && data.status > 2">
              {{ data.confirmTime }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="middle">
      <div class="install-body">
        <div class="install-body-list">
          <table>
            <thead>
              <tr class="list-header">
                <th class="header-item item-310">
                  产品信息
                </th>
                <th class="header-item item-416">
                  客户信息
                </th>
                <th class="header-item item-185">
                  数量
                </th>
                <th class="header-item item-229">
                  实付金额
                </th>
              </tr>
            </thead>
          </table>
          <table>
            <tbody class="list-line-item" v-for="i in 1" :key="i">
              <tr class="sep-row">
                <td colspan="4"></td>
              </tr>
              <tr>
                <td colspan="4" class="line-item-colspan">
                  <span class="order-number">
                    订单号：{{ data.orderNum }}
                  </span>
                  <span class="time"> 下单时间：{{ data.createDate }} </span>
                </td>
              </tr>
              <tr>
                <td class="line-item item-310">
                  <div class="img-or-name">
                    <img
                      :src="
                        data.product && $utils.picturePrefix(data.product.image)
                      "
                    />
                    <span style="width: 130px;">
                      {{ data.product && data.product.name }}
                    </span>
                  </div>
                </td>
                <td class="line-item item-416">
                  <div class="real-name">
                    {{ data.consigneeName }}
                  </div>
                  <div class="address">
                    {{
                      data.consigneeAddress &&
                        data.consigneeAddress.replace(/,/g, "")
                    }}
                  </div>
                  <div class="phone">
                    {{ data.consigneePhone }}
                  </div>
                </td>
                <td class="line-item item-185">
                  <div class="order-type">x{{ data.num || 0 }}</div>
                </td>
                <td class="line-item item-229">
                  <span class="real-pay" style="font-size: 16px;">
                    <!-- ￥{{ $utils.formatMoney(item.money) }} -->
                    ￥{{ $utils.formatMoney(data.money) }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style="font-size: 16px;margin-top: 15px;margin-bottom: 5px;">
          备注:
        </div>
        <el-input
          :value="data.remark"
          class="form-textarea"
          :rows="5"
          disabled
          placeholder="暂无备注"
        ></el-input>
      </div>
    </div>
    <div class="logistics">
      <div class="title">
        物流信息
      </div>
      <div class="result" v-if="activities.length > 0">
        <ul class="content">
          <li
            class="step"
            :class="{ end: i == activities.length - 1 }"
            v-for="(item, i) in activities"
            :key="i"
          >
            <div class="datetime">2021-08-13 19:27:20</div>
            <div class="step-icon"></div>
            <div class="text">
              {{ item.content }}
            </div>
          </li>
        </ul>
      </div>
      <empty description="暂无物流信息~" v-else></empty>
    </div>
    <div class="middle" v-show="data.status == 4">
      <div class="title">订单{{ refundStatusText[data.refundStatus] }}详情</div>
      <div class="refund-body">
        <div class="refund-form-item">
          <label> {{ refundStatusText[data.refundStatus] }}原因</label>
          <el-input
            :value="data.refundWhy"
            placeholder="暂无原因"
            disabled
          ></el-input>
        </div>
        <div class="refund-form-item">
          <label>备注</label>
          <el-input
            :value="data.refundNote"
            class="form-textarea"
            type="textarea"
            :rows="5"
            disabled
            placeholder="暂无备注"
          ></el-input>
        </div>
        <div class="title" style="margin: 40px 0 0;font-weight: normal;">
          {{ refundStatusText[data.refundStatus] }}审核
        </div>
        <div class="top">
          <div class="top-right">
            <div class="right-item">
              <div class="item">
                <!-- <img :src="data.status == 4 ? src_sel : src" /> -->
                <span
                  class="iconfont icon-tijiaoshenqing"
                  :class="{ 'iconfont-sel': data.status == 4 }"
                ></span>
                <div class="type" :class="{ arrive: data.status == 4 }">
                  提交{{ refundStatusText[data.refundStatus] }}申请
                </div>
              </div>
            </div>
            <img :src="data.auditStatus >= 1 ? arrows_sel : arrows" />
            <div class="right-item">
              <div class="item">
                <span
                  class="iconfont icon-shenhe"
                  :class="{ 'iconfont-sel': data.auditStatus >= 1 }"
                ></span>
                <div class="type" :class="{ arrive: data.auditStatus >= 1 }">
                  申请审核中
                </div>
              </div>
            </div>
            <img :src="data.auditStatus >= 2 ? arrows_sel : arrows" />
            <div class="right-item">
              <div class="item">
                <span
                  class="iconfont icon--neironganquanICON-"
                  :class="{ 'iconfont-sel': data.auditStatus >= 2 }"
                ></span>
                <div class="type" :class="{ arrive: data.auditStatus >= 2 }">
                  {{
                    data.auditStatus >= 2
                      ? data.auditStatus == 3
                        ? "审核失败"
                        : "审核成功"
                      : "审核结果"
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="refund-form-item"
          style="margin-top: 0px;"
          v-show="data.refundStatus != 1 && data.auditStatus == 3"
        >
          <div style="margin-bottom: 10px;">
            <label>审核失败原因</label>
          </div>
          <el-input
            :value="data.auditRemarke"
            class="form-textarea"
            type="textarea"
            :rows="5"
            disabled
            placeholder="暂无备注"
          ></el-input>
        </div>
        <div
          class="refund-body-item"
          v-show="
            data.auditStatus >= 2 &&
              data.auditStatus != 3 &&
              data.refundStatus != 1
          "
        >
          <div class="title" style="margin: 40px 0 20px;font-weight: normal;">
            客户填写物流
          </div>
          <el-form ref="kwForm" :model="kwForm" :rules="kwRules">
            <el-form-item class="form-item-1" label="物流名称:" prop="name">
              <el-input
                v-model="kwForm.name"
                placeholder="请输入物流名称"
                :disabled="isKwFrom"
              ></el-input>
            </el-form-item>
            <el-form-item label="物流单号:" class="form-item-2" prop="no">
              <el-input
                v-model="kwForm.no"
                placeholder="请输入物流单号"
                :disabled="isKwFrom"
              ></el-input>
              <div
                v-show="isKwFrom"
                style="position: absolute;right: -70px;top: 40px;cursor: pointer;color: #3072f6;"
                @click="logistics(kwForm.no, kwForm.name)"
              >
                查看物流
              </div>
            </el-form-item>
            <el-form-item class="form-item-3" label="凭证:" prop="imageUrl">
              <el-upload
                class="avatar-uploader"
                :class="{ 'avatar-uploader-disabled': isKwFrom }"
                action="*"
                :http-request="uploadImg"
                list-type="picture-card"
                :file-list="kwForm.imageUrl"
                :on-remove="handleAvatarRemove"
                :before-upload="beforeAvatarUpload"
                accept="image/*"
                :disabled="isKwFrom"
              >
                <i
                  class="el-icon-plus avatar-uploader-icon"
                  v-show="!isKwFrom"
                ></i>
              </el-upload>
            </el-form-item>
            <el-form-item v-show="!isKwFrom">
              <el-button type="primary" @click="openRefundLogistics">
                提交信息
              </el-button>
            </el-form-item>
          </el-form>
        </div>
        <div
          class="refund-form-item"
          style="margin-top: 0px;"
          v-show="data.refundStatus != 1 && data.auditStatus == 6"
        >
          <div style="margin-bottom: 10px;">
            <label>{{ refundStatusText[data.refundStatus] }}失败原因</label>
          </div>
          <el-input
            :value="data.refundFailNote"
            class="form-textarea"
            type="textarea"
            :rows="5"
            disabled
            placeholder="暂无备注"
          ></el-input>
        </div>
        <div
          class="refund-body-item"
          v-show="data.auditStatus == 5 && data.refundStatus == 3"
        >
          <div class="title" style="margin: 0px 0 20px;font-weight: normal;">
            商家返货物流
          </div>
          <el-form>
            <el-form-item class="form-item-1" label="物流名称:" prop="name">
              <el-input
                v-model="data.exchangeLogisticsName"
                placeholder="暂无物流名称"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="物流单号:" class="form-item-2" prop="no">
              <el-input
                v-model="data.exchangeLogisticsNum"
                placeholder="暂无物流单号"
                disabled
              ></el-input>
              <div
                v-show="isKwFrom"
                style="position: absolute;right: -70px;top: 40px;cursor: pointer;color: #3072f6;"
                @click="logistics(data.exchangeLogisticsNum, data.exchangeLogisticsName)"
              >
                查看物流
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <operation ref="operation"></operation>
    <tip-modal
      :visible="visibleTip"
      @close="handleClose"
      @ok="handleConfirmGoods"
    >
      <span>确定完成订单吗？</span>
    </tip-modal>
    <tip-modal
      :visible="visibleRefundLogistics"
      @close="handleClose"
      @ok="refundLogistics"
    >
      <span>确定提交物流信息吗？</span>
    </tip-modal>
    <logistics ref="logistics"></logistics>
  </div>
</template>

<script>
import Empty from "@/components/Empty";
import operation from "./operation";
import TipModal from "@/components/TipModal";
import Logistics from "./logistics/index.vue";

export default {
  components: {
    operation,
    Empty,
    TipModal,
    Logistics
  },
  data() {
    return {
      refundStatusText: ["", "退款", "退货", "换货"],
      type: 1,
      path: "/personal-center/purchase/place-order",
      src: require("@/assets/img/install/img-install-details-4.png"),
      src_sel: require("@/assets/img/install/img-install-details-sel-4.png"),
      arrows: require("@/assets/img/install/img-install-details-arrows.png"),
      arrows_sel: require("@/assets/img/install/img-install-details-sel-arrows.png"),
      activities: [],
      data: {},
      visibleTip: false,
      visibleRefundLogistics: false,
      // 客户物流信息
      kwForm: {
        name: "", // 名称
        no: "", // 物流编号
        imageUrl: [] // 图片凭证
      },
      isKwFrom: false, // 是否需要提交信息
      kwRules: {
        name: [
          {
            required: true,
            message: "物流名称不可为空",
            trigger: "change"
          }
        ],
        no: [
          {
            required: true,
            message: "物流单号不可为空",
            trigger: "change"
          }
        ],
        imageUrl: [
          {
            required: true,
            message: "请上传图片凭证",
            trigger: "change"
          }
        ]
      }
    };
  },
  mounted() {
    let type = this.$route.query.type;
    this.oid = this.$route.query.oid;
    this.type = Number(type);
    if (type == 1) {
      this.path = "/personal-center/purchase/place-order";
    } else if (type == 2) {
      this.path = "/personal-center/purchase/shipped";
    } else if (type == 3) {
      this.path = "/personal-center/purchase/completed";
    } else if (type == 4) {
      this.path = "/personal-center/purchase/refund";
    }
    this.getOrderDetail();
  },
  methods: {
    /**
     * 获取订单详情
     */
    getOrderDetail() {
      let params = { id: this.oid };
      const loading = this.$loading({
        lock: true,
        text: "查询中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      this.$api
        .getUWOrderDetail(params)
        .then(res => {
          console.log(res);
          this.data = res.data;
          this.isKwFrom = true;
          if (res.data.status == 4) {
            if (res.data.refundStatus != 1) {
              this.isKwFrom = res.data.auditStatus != 2;
            }
            if (res.data.refundLogisticsImage) {
              let imgs = res.data.refundLogisticsImage.split(",");
              let imageUrl = [];
              imgs.map(imgPath => {
                imageUrl.push({
                  filePath: imgPath,
                  name: new Date().getTime().toString(),
                  url: this.$utils.picturePrefix(imgPath)
                });
              });
              this.kwForm = {
                name: res.data.refundLogisticsName, // 名称
                no: res.data.refundLogisticsNum, // 名称
                imageUrl: imageUrl // 图片凭证
              };
            }
          }
          if(res.data.logisticsNum) this.getLogistics(res.data.logisticsNum);
        })
        .catch(err => {
          this.$message.error(err?.msg);
        })
        .finally(() => {
          loading.close();
        });
    },

    /**
     * 把订单状态数字转换成文本
     * @param {*} status
     */
    statusName(status) {
      let data = this.data;
      switch (status) {
        case 1:
          return "已下单";
        case 2:
          return "已发货";
        case 3:
          return "已完成";
        case 4:
          if (data.refundStatus == 1) {
            if (data.auditStatus == 1) {
              return "退款中";
            } else {
              return "退款成功";
            }
          } else if (data.refundStatus == 2) {
            if (data.auditStatus < 5 && data.auditStatus != 3) {
              return "退货中";
            } else if (data.auditStatus == 5) {
              return "退货成功";
            } else {
              return "退货失败";
            }
          } else {
            if (data.auditStatus < 5 && data.auditStatus != 3) {
              return "换货中";
            } else if (data.auditStatus == 5) {
              return "换货成功";
            } else {
              return "换货失败";
            }
          }
        // return "退货中";
        default:
          return "-";
      }
    },

    /**
     * 查询物流地址
     * @param {*} logisticsNumber 物流单号
     */
    getLogistics(logisticsNumber) {
      if (
        (logisticsNumber.indexOf("SF") == 0 || logisticsNumber.indexOf("sf") == 0) &&
        this.data.consigneePhone
      ) {
        logisticsNumber += ":" + this.data.consigneePhone.substring(7, 11);
      }
      //
      let params = { logisticsNumber };
      this.$api
        .getLogistics(params)
        .then(res => {
          this.activities = res.data;
        })
        .catch(err => {
          this.$message.error(err?.msg);
        });
    },

    /**
     *
     */
    handleApplyRefund(type) {
      this.$refs.operation.init(this.data.id, type);
    },

    /**
     * 打开完成二次确认界面
     */
    handleOpenConfirmGoods() {
      this.visibleTip = true;
    },

    /**
     * 确认完成接口
     */
    handleConfirmGoods() {
      // this.visibleTip = true;
      const loading = this.$loading({
        lock: true,
        text: "提交中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      this.$api
        .completeOrder({ id: this.oid })
        .then(() => {
          this.$message({
            message: "恭喜您，该订单已完成！",
            type: "success"
          });
          this.visibleTip = false;
          this.isKwFrom = true;
          this.getOrderDetail();
        })
        .catch(err => {
          this.$message.error(err?.msg);
        })
        .finally(() => {
          loading.close();
        });
    },

    /**
     * 关闭弹框
     */
    handleClose() {
      this.visibleTip = false;
      this.visibleRefundLogistics = false;
    },

    /**
     * 删除图片
     */
    handleAvatarRemove(file, fileList) {
      this.kwForm.imageUrl = fileList;
    },
    /**
     * 上传前图片判断
     */
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 10MB!");
      }
      return isLt2M;
    },

    /**
     * 上传图片
     */
    uploadImg(param) {
      let file = param.file;
      let form = new FormData();
      form.append("file", file);
      const loading = this.$loading({
        lock: true,
        text: "上传中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      this.$api
        .uploadImg(form)
        .then(res => {
          file.filePath = res.data.filePath;
          file.url = this.$utils.picturePrefix(res.data.filePath);
          this.kwForm.imageUrl.push(file);
        })
        .catch(err => {
          this.$message.error(err?.msg);
        })
        .finally(() => {
          loading.close();
        });
    },

    openRefundLogistics() {
      this.$refs.kwForm.validate(valid => {
        if (valid) {
          this.visibleRefundLogistics = true;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    /**
     * 提交物流信息
     */
    refundLogistics() {
      let kwForm = this.kwForm;
      let img = "";
      kwForm.imageUrl.map((item, i) => {
        img += item.filePath;
        if (i < kwForm.imageUrl.length - 1) {
          img += ",";
        }
      });
      let parmas = {
        refundLogisticsName: kwForm.name,
        refundLogisticsNum: kwForm.no,
        refundLogisticsImage: img,
        id: this.oid
      };
      const loading = this.$loading({
        lock: true,
        text: "提交中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      this.$api
        .refundLogistics(parmas)
        .then(() => {
          this.$message({
            message: "恭喜您，提交成功！",
            type: "success"
          });
          this.visibleRefundLogistics = false;
          this.isKwFrom = true;
          this.getOrderDetail();
        })
        .catch(err => {
          this.$message.error(err?.msg);
        })
        .finally(() => {
          loading.close();
        });
    },

    logistics(logisticsNum, logisticsName) {
      this.$refs.logistics.init(logisticsNum, logisticsName, this.data.consigneePhone);
    }
  }
};
</script>

<style lang="less">
@import "./index.less";
</style>
